import React from 'react'
import HeaderHero from '../Layout/HeaderHero'
import headerBg from '../Assets/backdrops/hero-bg-1.jpg'
import HomeForm from '../Components/HomeForm'
import '../Stylings/home.css'
import HomeServices from '../Components/HomeServices'
import HomeChoose from '../Components/HomeChoose'
const Home = () => {
  return (
    <div>
        <HeaderHero bgImage={headerBg} subtitle="Introducing" title="Black Shield Security: Your Trusted Guardian in Canada" desc="Welcome to Black Shield Security – your premier choice for top-notch security services in Canada. With a steadfast commitment to safeguarding what matters most to you, we stand as the vanguard of security solutions across the nation."/>
        <HomeForm/>
        <HomeServices/>
        <HomeChoose/>
    </div>
  )
}

export default Home