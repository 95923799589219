import React from 'react';
import bgImage from '../Assets/backdrops/call-to-action.avif'
import { Link } from 'react-router-dom';
const CallToAction = () => {
  return (
    <section className="text-white text-center call-to-action" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="dark-overlay">
        <div className="container">
          <p className=" mb-4 section-title">Have an enquiry?</p>
          <div className='d-flex justify-content-center'>
            <button className='btn btn-warning btn-md text-white'><Link to="/contact" className='nav-link'>Get Quote</Link></button>
            <button className="btn btn-outline-warning btn-md mx-2"><a href="tel:+12368892105" className='nav-link'>Call Us Now</a></button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
