import React from 'react';
import serv1 from '../Assets/Images/serv1.jpg';
import serv2 from '../Assets/Images/serv2.jpg';
import serv3 from '../Assets/Images/serv3.jpg';
import serv4 from '../Assets/Images/serv4.jpg';

const ServicesAll = () => {
    const servicesData = [
        {
            title: 'Manned Guarding',
            description:
                'Trust in our highly trained and licensed security personnel for your on-site protection. Whether in uniform or plainclothes, our officers excel in access control and visitor management, providing a visible deterrent to potential threats.',
            uspList: [
                'Highly Trained Personnel',
                'Versatile Security Solutions',
                'Visible Deterrent to Threats',
                'Proactive Threat Prevention',
            ],
            bgi: serv1,
        },
        {
            title: 'Patrol Services',
            description:
                'Benefit from our mobile and foot patrols, designed to cover specific areas and respond rapidly to potential security incidents. Our presence ensures a visible deterrent and proactive threat prevention.',
            uspList: [
                'Mobile and Foot Patrols',
                'Rapid Response to Incidents',
                'Visible Deterrent',
                'Proactive Threat Prevention',
            ],
            bgi: serv2,
        },
        {
            title: 'Event Security',
            description:
                'Plan and execute events with confidence, knowing our specialized event security services are in place. From crowd management to VIP protection, we tailor our approach to meet the unique security challenges of your event.',
            uspList: [
                'Specialized Event Security',
                'Crowd Management',
                'VIP Protection',
                'Tailored Approach',
            ],
            bgi: serv4,
        },
        {
            title: 'Access Control',
            description:
                'Implement robust access control measures to regulate entry and exit points, ensuring only authorized individuals have access to specific areas of your premises. Our access control systems offer flexibility, scalability, and enhanced security.',
            uspList: [
                'Regulate Entry and Exit Points',
                'Authorize Access for Specific Areas',
                'Flexible and Scalable Systems',
                'Enhanced Security Measures',
            ],
            bgi: serv3,
        },
        {
            title: 'Loss Prevention',
            description:
                'Minimize losses and protect your assets with our comprehensive loss prevention strategies. From surveillance systems to inventory management solutions, we help businesses mitigate risks and prevent financial losses due to theft or fraud.',
            uspList: [
                'Comprehensive Loss Prevention Strategies',
                'Surveillance Systems',
                'Inventory Management Solutions',
                'Risk Mitigation and Prevention',
            ],
            bgi: serv1,
        },
        {
            title: 'Private Security',
            description:
                'Ensure the safety and security of your private property, residences, or events with our dedicated private security services. Our highly trained personnel offer discreet protection, tailored to your specific requirements and concerns.',
            uspList: [
                'Dedicated Private Security Personnel',
                'Discreet Protection Services',
                'Tailored Security Solutions',
                'Enhanced Peace of Mind',
            ],
            bgi: serv2,
        },
        {
            title: 'Concierge Security',
            description:
                'Elevate the level of security and hospitality at your establishment with our professional concierge security services. Our trained personnel provide a welcoming presence while ensuring the safety and well-being of residents, guests, and employees.',
            uspList: [
                'Professional Concierge Security Personnel',
                'Welcoming and Hospitable Presence',
                'Safety and Well-being Assurance',
                'Enhanced Customer Experience',
            ],
            bgi: serv4,
        },
    ];

    return (
        <div className='my-3 py-3'>
            {servicesData.map((service, index) => (
                <div className='my-3' key={index}>
                    <div
                        className='container service-card p-0 rounded-2 bg-dark text-white'
                        style={{ backgroundImage: `url(${service.bgi})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}
                    >
                        <div className="dark-overlay p-5 rounded-2">
                            <p className='card-title text-warning'>{`0${index + 1}.`}</p>
                            <p className='section-title'>{service.title}</p>
                        </div>
                    </div>
                    <div className="container my-3">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p className='section-content'>{service.description}</p>
                            </div>
                            <div className='col-lg-6'>
                                <ul>
                                    {service.uspList.map((usp, uspIndex) => (
                                        <li key={uspIndex}>{usp}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ServicesAll;
