import React, { useState } from 'react';

const AboutVision = () => {
  const coreValues = [
    {
      title: 'Client Empowerment',
      content:
        'Empower our clients with the confidence that their security is in capable hands, allowing them to focus on their core activities without concerns about safety.',
    },
    {
      title: 'Proactive Security',
      content:
        'Anticipate and address security challenges before they become threats, ensuring a proactive and preventive approach to safeguarding our clients.',
    },
    {
      title: 'Professionalism',
      content:
        'Uphold the highest standards of professionalism in all interactions, representing the trust and reliability that Black Shield Security is known for.',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="about-vision py-5">
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-6">
            <p className="section-title">Our Mission</p>
          </div>
          <div className="col-lg-6">
            {coreValues.map((value, index) => (
              <div className="card acc-card" key={index}>
                <div
                  className={`card-header bg-white d-flex justify-content-between ${index === activeIndex ? '' : 'collapsed'}`}
                  onClick={() => setActiveIndex(index === activeIndex ? -1 : index)}
                >
                  <div>{value.title}</div>
                  <i className={` text-warning fa fa-arrow-${index === activeIndex ? 'up' : 'down'}`}></i>
                </div>
                {index === activeIndex && (
                  <div className="card-body">{value.content}</div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="row py-5">
          <div className="col-lg-6">
            <p className="section-title">Our Vision</p>
          </div>
          <div className="col-lg-6">
            At Black Shield Security, we envision a world where individuals, businesses, and communities thrive without the fear of security threats. Our vision is to be the premier security services provider, setting the standard for excellence, innovation, and reliability in safeguarding what matters most.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutVision;
