import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../src/Views/Home'
import About from '../src/Views/About'
import Services from '../src/Views/Services'
import Contact from '../src/Views/Contact'
import CareersSection from './Components/CareersSection'
import Career from './Views/Career'
import FormSubmissions from './Components/FormSubmissions'
import Submissions from './Views/Submissions'
const RouterOutlet = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/career" element={<Career/>}/>
      <Route path="/form-subs" element={<Submissions/>}/>
    </Routes>
  )
}

export default RouterOutlet