import React, { useState } from 'react';
import axios from 'axios';

const HomeForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://bs-backend-1pw8.onrender.com/api/quote', formData);

            if (response.status === 200) {
                console.log(response.data.message); // Log the server response
                alert("Quote has been sent successfully!");
            } else {
                console.error('Failed to submit the form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }

        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            message: ''
        });
    };

    return (
        <div className='home-form'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center">
                        <div className="location-info d-flex">
                            <div className="location-icon">
                                <i className="fas fa-map-marker-alt"></i>
                            </div>
                            <p className="location-address phone-number">
                                Unit 916, 10620 150 Street, Surrey, BC
                            </p>
                        </div>
                        <p className="section-title">
                            Secure Your Peace of Mind with Black Shield Security
                        </p>
                        <p className="section-content">
                            Discover unparalleled security solutions with Black Shield Security, your trusted guardian in Canada. Our commitment to safeguarding what matters most is reflected in every aspect of our services.
                        </p>
                        <div className="phone-info d-flex mt-3">
                            <div className="phone-icon">
                                <i className="fas fa-phone"></i>
                            </div>
                            <p className="phone-number">2368892105</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="quote-form">
                            <h3 className="mb-4 section-title font-bold">Get a Quote</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name:</label>
                                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email:</label>
                                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label">Message:</label>
                                    <textarea className="form-control" id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
                                </div>

                                <button type="submit" className="btn btn-warning text-white btn-md mt-3">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeForm;
