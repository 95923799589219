import React from 'react'
import HeaderHero from '../Layout/HeaderHero'
import headerBg from '../Assets/backdrops/hero-bg-1.jpg'
import AboutSection from '../Components/AboutSection'
import '../Stylings/about.css'
import HomeChoose from '../Components/HomeChoose'
import AboutVision from '../Components/AboutVision'
const About = () => {
  return (
    <div>
        <HeaderHero bgImage={headerBg} subtitle="" title="About Us" desc="With a steadfast commitment to safeguarding what matters most to you, we stand as the vanguard of security solutions across the nation."/>
        <AboutSection/>
        <HomeChoose/>
        <AboutVision/>
    </div>
  )
}

export default About