// FormSubmissions.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FormSubmissions = () => {
  const [contactSubmissions, setContactSubmissions] = useState([]);
  const [quoteSubmissions, setQuoteSubmissions] = useState([]);
  const [careerSubmissions, setCareerSubmissions] = useState([]);
  const [activeTab, setActiveTab] = useState('contact'); // Default active tab

  useEffect(() => {
    // Fetch form submissions when the component mounts
    fetchFormSubmissions(activeTab);
  }, [activeTab]);

  const fetchFormSubmissions = async (formType) => {
    try {
      const response = await axios.get(`https://bs-backend-1pw8.onrender.com/api/${formType}-submissions`);
      if (response.status === 200) {
        switch (formType) {
          case 'contact':
            setContactSubmissions(response.data);
            break;
          case 'quote':
            setQuoteSubmissions(response.data);
            break;
          case 'career':
            setCareerSubmissions(response.data); // Corrected to set careerSubmissions state
            break;
          default:
            break;
        }
      } else {
        console.error(`Failed to fetch ${formType} form submissions`);
      }
    } catch (error) {
      console.error(`Error fetching ${formType} form submissions:`, error);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderSubmissions = (submissions) => {
    return (
      <tbody>
        {submissions.map((submission) => (
          <tr key={submission._id}>
            <td>{submission.name}</td>
            <td>{submission.email}</td>
            <td>{submission.subject || submission.position}</td>
            <td>{submission.message || submission.resumeLink}</td>
          </tr>
        ))}
      </tbody>
    );
  };
  const renderCareers = (submissions) => {
    let link=''
    return (
      <tbody>
        {submissions.map((submission) => (
          <tr key={submission._id}>
            <td>{submission.name}</td>
            <td>{submission.email}</td>
            <td>{submission.position}</td>
            <td>{  (<a href={`https://bs-backend-1pw8.onrender.com/${submission.resumePath}`} target='_blank'>View</a>)}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div>
      <h2 className='fw-bold mb-5'>Form Submissions</h2>

      {/* Tabs */}
      <ul className="nav nav-tabs mb-5">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'contact' ? 'active' : ''}`}
            onClick={() => handleTabClick('contact')}
          >
            Contact Form
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'quote' ? 'active' : ''}`}
            onClick={() => handleTabClick('quote')}
          >
            Quote Form
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'career' ? 'active' : ''}`}
            onClick={() => handleTabClick('career')}
          >
            Career Form
          </button>
        </li>
      </ul>

      {/* Tab content */}
      <div className="tab-content">
        <div className={`tab-pane ${activeTab === 'contact' ? 'show active' : ''}`}>
          <h5>Contact Form Submissions</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Message</th>
              </tr>
            </thead>
            {renderSubmissions(contactSubmissions)}
          </table>
        </div>

        <div className={`tab-pane ${activeTab === 'quote' ? 'show active' : ''}`}>
          <h3>Quote Form Submissions</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Message</th>
              </tr>
            </thead>
            {renderSubmissions(quoteSubmissions)}
          </table>
        </div>

        <div className={`tab-pane ${activeTab === 'career' ? 'show active' : ''}`}>
          <h3>Career Form Submissions</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Position</th>
                <th>Resume Link</th>
              </tr>
            </thead>
            {renderCareers(careerSubmissions)}
          </table>
        </div>
      </div>
    </div>
  );
};

export default FormSubmissions;
