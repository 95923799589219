import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Assets/BS.png'
const Footer = () => {
  const handleClick = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div>

      <footer class=" text-lg-start bg-body-tertiary text-muted">




        <section class="pt-5">
          <div class="container text-md-start mt-5">

            <div class="row mt-3">

              <div class="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4">

                <h6 class="text-uppercase fw-bold mb-4">
                  <img src={Logo} alt="" srcset=""  width={"150px"}/>
                </h6>
                <p className='section-content' style={{fontSize:"90%"}}>
                With a steadfast commitment to safeguarding what matters most to you, we stand as the vanguard of security solutions across the nation.  </p>
              </div>



              <div class="col-md-4 col-lg-2 col-xl-2 mx-auto mb-4">

                <h6 class="text-uppercase fw-bold mb-4">
                  Quick Links
                </h6>
                <p>
                  <Link onClick={handleClick} to="/" class="text-reset">Home</Link>
                </p>
                <p>
                  <Link onClick={handleClick} to="/about" class="text-reset">About</Link>
                </p>
                <p>
                  <Link onClick={handleClick} to="/services" class="text-reset">Products</Link>
                </p>
                <p>
                  <Link onClick={handleClick} to="/career" class="text-reset">Career</Link>
                </p>
                <p>
                  <Link onClick={handleClick} to="/contact" class="text-reset">Contact</Link>
                </p>
              </div>






              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
                <p><i class="fas fa-home me-3"></i> Unit 916, 10620 150 Street, Surrey, BC</p>
                <p>
                  <i class="fas fa-envelope me-3"></i>
                  info@blackshieldsecurity.ca
                </p>
                <p><i class="fas fa-phone me-3"></i> 2368892105</p>
                <p><i class="fas fa-phone me-3"></i> 7788229840</p>
              </div>

            </div>

          </div>
        </section>



        <div class="text-center p-4">
          © 2024 Copyright:
          <a class="text-reset fw-bold">blackshieldsecurity.ca</a>
        </div>

      </footer>

    </div>
  )
}

export default Footer