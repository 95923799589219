import React from 'react'
import HeaderHero from '../Layout/HeaderHero'
import headerBg from '../Assets/backdrops/hero-bg-1.jpg'
import CareersSection from '../Components/CareersSection'
import HomeChoose from '../Components/HomeChoose'
const Career = () => {
    return (
        <div>
            <HeaderHero bgImage={headerBg} subtitle="" title="Careers" desc="With a steadfast commitment to safeguarding what matters most to you, we stand as the vanguard of security solutions across the nation." />
            <CareersSection/>
            <HomeChoose/>
        </div>
    )
}

export default Career