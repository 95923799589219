import React from 'react'
import HeaderHero from '../Layout/HeaderHero'
import headerBg from '../Assets/backdrops/hero-bg-1.jpg'
import ServicesAll from '../Components/ServicesAll'
import '../Stylings/services.css'
import WorkingProcess from '../Components/WorkingProcess'
const Services = () => {
  return (
    <div>
      <HeaderHero bgImage={headerBg} title="Services" desc="Explore our comprehensive offerings tailored to safeguard what matters most to you."/>
      <ServicesAll/>
      <WorkingProcess/>  
    </div>
  )
}

export default Services