import React from 'react';
import { Link } from 'react-router-dom';
import sv1 from '../Assets/Images/svi.jpg'
import sv2 from '../Assets/Images/sv2.jpg'
import sv3 from '../Assets/Images/sv3.jpg'
import sv4 from '../Assets/Images/sv4.jpg'
const HomeServices = () => {
    const cardDatas = [
        {
            number: '01',
            title: 'Manned Guarding',
            paragraphs: [
                'Trained and licensed security personnel',
                'Uniformed or plainclothes officers',
                'Access control and visitor management',
            ],
            learnMoreLink: '/services',
            bgi: sv1
        },
        {
            number: '02',
            title: 'Patrol Services',
            paragraphs: [
                'Foot patrols for specific areas',
            ],
            learnMoreLink: '/services',
            bgi: sv2
        },
        {
            number: '03',
            title: 'Event Security',
            paragraphs: [
                'Crowd management',
                'VIP protection',
                'Security planning for events',
            ],
            learnMoreLink: '/services',
            bgi: sv3
        },
        {
            number: '04',
            title: 'Security Consultation',
            paragraphs: [
                'Site security assessments',
                'Threat and vulnerability analysis',
                ' Security strategy development',
            ],
            learnMoreLink: '/services',
            bgi: sv4
        },
    ];

    return (
        <div className='home-services'>
            <div className="row">
                {cardDatas.map((cardData, index) => (
                    <div key={index} className="text-white col-lg-3 col-md-6 col-sm-12 home-services-card" style={{ backgroundImage: `url(${cardData.bgi})` }}>
                        <div className="sub-con">
                            <div className='card-num card-link'>
                                {cardData.number}.
                            </div>
                            <div>
                                <p className="card-title pb-3">
                                    {cardData.title}
                                </p>
                                {cardData.paragraphs.map((paragraph, pIndex) => (
                                    <p key={pIndex} className='card-para py-2'>
                                        <i className=''></i>
                                         {paragraph}
                                    </p>
                                ))}
                                <div className='mt-3'>
                                <Link to={cardData.learnMoreLink} className='card-link'>
                                Learn More <i className='fa fa-arrow-right'></i>
                                </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HomeServices;
