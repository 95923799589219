import React, { useState } from 'react';
import axios from 'axios';

const CareersSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        position: '',
        resume: null // Change to accept file uploads
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            resume: file
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('position', formData.position);
            formDataToSend.append('resume', formData.resume);

            const response = await axios.post('https://bs-backend-1pw8.onrender.com/api/careers', formDataToSend);

            if (response.status === 200) {
                console.log(response.data.message);
                alert("Resume Has Been Sent Successfully");
            } else {
                console.error('Failed to submit the application');
            }
        } catch (error) {
            console.error('Error submitting application:', error);
        }

        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            position: '',
            resume: null
        });
    };

    return (
        <div id="careers" className="careers-area section-padding bg-light">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="careers-info py-5">
                            <h2>Join Our Team</h2>
                            <p>We are always looking for talented individuals to join our team. Submit your application now!</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="careers">
                            <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control mb-4" placeholder="Name" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control mb-4" placeholder="Email" required />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <input type="text" name="position" value={formData.position} onChange={handleChange} className="form-control mb-4" placeholder="Desired Position" required />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <input type="file" name="resume" onChange={handleFileChange} className="form-control-file mb-4" required />
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="btn btn-warning text-white" title="Submit Your Application!">Submit Application</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareersSection;
