import React from 'react'
import Navbar from '../Layout/Navbar'
import FormSubmissions from '../Components/FormSubmissions'
import Footer from '../Layout/Footer'
import HeaderHero from '../Layout/HeaderHero'
import headerBg from '../Assets/backdrops/hero-bg-1.jpg'
import HomeChoose from '../Components/HomeChoose'
const Submissions = () => {
    return (
        <div>

            <HeaderHero bgImage={headerBg} subtitle="" title="Submission" desc="With a steadfast commitment to safeguarding what matters most to you, we stand as the vanguard of security solutions across the nation." />
            <div className="container py-5 my-5">
                <FormSubmissions />
            </div>
        </div>
    )
}

export default Submissions