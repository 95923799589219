import React from 'react';

const WorkingProcess = () => {
  const steps = [
    {
      number: '01.',
      title: 'Consultation',
      description:
        'Our security experts begin by conducting thorough consultations to understand your specific security needs and concerns. We assess your vulnerabilities, gather essential information, and work collaboratively to design a tailored security plan.',
    },
    {
      number: '02.',
      title: 'Proposal',
      description:
        'Based on the insights gathered during the consultation, we present a detailed security proposal. This includes a comprehensive overview of recommended security measures, technology solutions, and personnel requirements. We ensure transparency and clarity in our proposals.',
    },
    {
      number: '03.',
      title: 'Installation',
      description:
        'Once the proposal is accepted, our skilled team proceeds with the installation of security systems, equipment, and any necessary infrastructure. We prioritize efficiency and precision to ensure seamless integration, minimizing any disruption to your operations.',
    },
    {
      number: '04.',
      title: 'Inspection',
      description:
        'Regular inspections are a vital part of our security service. Our team conducts thorough inspections to evaluate the effectiveness of security measures in place. We identify potential improvements, address emerging risks, and ensure continuous optimization of your security infrastructure.',
    },
  ];

  return (
    <div className='working-process pb-5 mb-5'>
      <div className='container'>
        <div className="row">
            <p className="section-title my-3">
                Our Working Process
            </p>
        </div>
        <div className='row my-3'>
          {steps.map((step, index) => (
            <div key={index} className='col-lg-3 col-md-6 col-sm-12'>
              <div className='process-step'>
                <p className='step-number fw-bold'>{step.number}</p>
                <hr className='bg-warning text-warning fw-bold'/>
                <p className='step-title mb-3 fw-bold ' style={{fontSize:"120%", textAlign:"justify"}}>{step.title}</p>
                <p className='step-description' style={{fontSize:"90%", textAlign:"justify"}}>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkingProcess;
 