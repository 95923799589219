import React from 'react';

const HomeChoose = () => {
  const keyPoints = [
    {
      icon: 'fas fa-check-circle',
      title: 'Proven Excellence:',
      text: 'Our track record speaks for itself, showcasing our dedication to providing top-tier protection.',
    },
    {
      icon: 'fas fa-users',
      title: 'Community Integration:',
      text: 'As a locally rooted company, Black Shield Security values community relationships.',
    },
    {
      icon: 'fas fa-shield-alt',
      title: 'Holistic Security Approach:',
      text: 'From cutting-edge surveillance systems to highly trained security personnel, we deliver holistic security solutions tailored to your unique needs.',
    },
    {
      icon: 'fas fa-clock',
      title: '24/7 Vigilance:',
      text: 'Security is a round-the-clock concern. Black Shield Security operates 24/7, 365 days a year.',
    },
    {
      icon: 'fas fa-cogs',
      title: 'Leading-edge Technology:',
      text: 'At Black Shield Security, we stay ahead of the curve by embracing the latest advancements in security technology.',
    },
    {
      icon: 'fas fa-cogs',
      title: 'Customized Security Plans:',
      text: 'Recognizing that every client is unique, we collaborate closely with you to develop customized security plans.',
    },
  ];


  return (
    <div className='home-choose'>
      <div className="container">
        <div className="row mb-3 pb-3">
          <div className="col-12 text-center">
            <p className="section-title">
              Why Choose Us
            </p>
            <p className="section-content">
              With a steadfast commitment to safeguarding what matters most to you, we stand as the vanguard of security solutions across the nation.
            </p>
          </div>
        </div>
        <div className="row">
          {keyPoints.map((point, index) => (
            <div key={index} className="my-3 col-lg-4 col-md-6 col-sm-12">
              <div className=" my-cards">
                <div className="icon-container mb-3">
                  <div className={`icon ${point.icon} card-icon`}></div>
                </div>
                <div className="card-body">
                  <h5 className="card-title mb-3">{point.title}</h5>
                  <p className="card-text">{point.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeChoose;
