import React from 'react'
import { Link } from 'react-router-dom'
const HeaderHero = ({ bgImage, subtitle, title, desc }) => {
    return (
        <div className='hero-header' style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="overlay-dark">
                <div className="container text-white">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2>{subtitle}</h2>
                            <h1>{title}</h1>
                            <p>{desc}</p>
                            <div className="cta-buttons py-3">
                                <button className='header-button btn-md btn btn-warning text-white'>
                                    <Link className='nav-link text-white' to='/contact'>
                                    Get Quote
                                    </Link>
                                </button>
                                <button className='btn btn-md btn-outline-warning mx-4 border-0'>
                                    <Link className='nav-link' to='/services'> View Services <i className='fa fa-arrow-right'></i>
                                    </Link> 
                                </button>
                            </div>
                            <div className="social-icons color-warning mt-3">
                                <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                                <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                                <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderHero