import React from "react";
import Navbar from '../src/Layout/Navbar'
import Footer from '../src/Layout/Footer'
import RouterOutlet from '../src/RouterOutlet'
import CallToAction from "./Layout/CallToAction";

function App() {
  return (
    <div>
      <Navbar/>
      <RouterOutlet/>
      <CallToAction/>
      <Footer/>
    </div>
  );
}

export default App;
