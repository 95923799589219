import React, { useState } from 'react';
import axios from 'axios';

const ContactUsMain = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://bs-backend-1pw8.onrender.com/api/contact', formData);

            if (response.status === 200) {
                console.log(response.data.message); // Log the server response
                // Optionally, you can reset the form here
                alert("Form Submitted")
            } else {
                console.error('Failed to submit the form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div id="contact" className="contact-area section-padding bg-light">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="contact">
                            <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control mb-4" placeholder="Name" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control mb-4" placeholder="Email" required />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <input type="text" name="subject" value={formData.subject} onChange={handleChange} className="form-control mb-4" placeholder="Subject" required />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <textarea rows="6" name="message" value={formData.message} onChange={handleChange} className="form-control mb-4" placeholder="Your Message" required></textarea>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="btn btn-contact-bg" title="Submit Your Message!">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="single_address">
                            <i className="fa fa-map-marker"></i>
                            <h4>Our Address</h4>
                            <p>Unit 916, 10620 150 Street, Surrey, BC</p>
                        </div>
                        <div className="single_address">
                            <i className="fa fa-envelope"></i>
                            <h4>Send your message</h4>
                            <p>info@blackshieldsecurity.ca</p>
                        </div>
                        <div className="single_address">
                            <i className="fa fa-phone"></i>
                            <h4>Call us on</h4>
                            <p>2368892105</p>
                        </div>
                        <div className="single_address">
                            <i className="fa fa-clock-o"></i>
                            <h4>Work Time</h4>
                            <p>Mon - Fri: 08.00 - 16.00. <br />Sat: 10.00 - 14.00</p>
                        </div>
                    </div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.296407617868!2d-122.8051408!3d49.194936399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d70c57c1b3b7%3A0x6cc911b3930f7fb3!2s10620%20150%20St%20%23916%2C%20Surrey%2C%20BC%20V3R%207S1%2C%20Canada!5e0!3m2!1sen!2sin!4v1706247871745!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
}

export default ContactUsMain;
