import React from 'react'
import aboutImage from '../Assets/Images/about-image.jpg'
const AboutSection = () => {
    return (
        <div className='about-section py-5 mt-5'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <img src={aboutImage} alt="" srcset="" width={"100%"} className='rounded-2' />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center">
                        <i className='fa fa-arrow-right text-warning'></i>
                        <p className="section-title">

                            Let us be the shield that guards what matters most to you.
                        </p>
                        <p className="section-content">
                            At Black Shield Security, our mission is to provide unparalleled security solutions, ensuring the safety and well-being of our clients and their assets. With a commitment to excellence, integrity, and innovation, we stand as a beacon of reliability in the security industry.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection