import React from 'react'
import ContactUsMain from '../Components/ContactUsMain'
import '../Stylings/contact.css'
import HeaderHero from '../Layout/HeaderHero'
import bgi from '../Assets/backdrops/hero-bg-1.jpg'
const Contact = () => {
  return (
    <div>
      <HeaderHero title="Contact Us" desc="Our team of professionals would respond you as soon as we can" bgImage={bgi}/>
      <ContactUsMain/>
    </div>
  )
}

export default Contact